import React from 'react'
import { Link, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import ColWrap from '../components/Helpers/ColWrap'
import Layout from '../components/Layout'

import homeScanImg from '../img/icon-scan.png'
import homePlanImg from '../img/icon-plan.png'
import homeRenoImg from '../img/icon-renoveer.png'
import homeGenietImg from '../img/icon-geniet.png'

const Energetisch = ({ data }) => {
  const {
    eno_intro_title: EnoIntroTitle,
    eno_intro_text: EnoIntroText,
    eno_stap1_title: EnoStap1Title,
    eno_stap1_text: EnoStap1Text,
    eno_stap2_title: EnoStap2Title,
    eno_stap2_text: EnoStap2Text,
    eno_stap3_title: EnoStap3Title,
    eno_stap_3_text: EnoStap3Text,
    eno_stap4_title: EnoStap4Title,
    eno_stap4_text: EnoStap4Text,
    eno_outro_tekst: EnoOutroText,
    eno_outro_btn1_text: EnoOBtn1Text,
    eno_outro_btn2_text: EnoOBtn2Text,
    meta_description: metadesc,
    meta_tags: metatags,
  } = data.eno.acf

  const { title: sitename } = data.site.siteMetadata
  const { title: pageTitle } = data.eno

  return (
    <Layout>
      <Helmet>
        <title>{`${pageTitle} | ${sitename}`}</title>
        {metadesc ? <meta name="description" content={metadesc} /> : null}
        {metatags ? <meta name="keywords" content={metatags} /> : null}
      </Helmet>
      <section className="enereno-intro section color-bg">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-8 has-text-centered">
              <h1 className="title">{EnoIntroTitle}</h1>
              <div dangerouslySetInnerHTML={{ __html: EnoIntroText }} />
            </div>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <ColWrap>
            <div className="column is-2">
              <img src={homePlanImg} alt="stapIcon" className="enoPlanImg" />
            </div>
            <div className="column is-10">
              <h2>{EnoStap1Title}</h2>
              <div
                className="content"
                dangerouslySetInnerHTML={{ __html: EnoStap1Text }}
              />
            </div>
          </ColWrap>
        </div>
      </section>

      <section className="section color-bg">
        <div className="container">
          <ColWrap>
            <div className="column is-2">
              <img src={homeScanImg} alt="stapIcon" className="enoScanImg" />
            </div>
            <div className="column is-10">
              <h2>{EnoStap2Title}</h2>
              <div
                className="content"
                dangerouslySetInnerHTML={{ __html: EnoStap2Text }}
              />
            </div>
          </ColWrap>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <ColWrap>
            <div className="column is-2">
              <img src={homeRenoImg} alt="stapIcon" className="enoRenoImg" />
            </div>
            <div className="column is-10">
              <h2>{EnoStap3Title}</h2>
              <div
                className="content"
                dangerouslySetInnerHTML={{ __html: EnoStap3Text }}
              />
            </div>
          </ColWrap>
        </div>
      </section>

      <section className="section color-bg">
        <div className="container">
          <ColWrap>
            <div className="column is-2">
              <img
                src={homeGenietImg}
                alt="stapIcon"
                className="enoGenietImg"
              />
            </div>
            <div className="column is-10">
              <h2>{EnoStap4Title}</h2>
              <div
                className="content"
                dangerouslySetInnerHTML={{ __html: EnoStap4Text }}
              />
            </div>
          </ColWrap>
        </div>
      </section>

      <section className="content section has-text-centered">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-8 has-text-centered">
              <div
                className="content"
                dangerouslySetInnerHTML={{ __html: EnoOutroText }}
              />
              <div className="cta-double">
                <Link to="/contact" className="cta-link">
                  {EnoOBtn1Text}
                </Link>
                <Link to="/wie" className="cta-link">
                  {EnoOBtn2Text}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Energetisch

export const pageQuery = graphql`
  query EnoPage {
    site {
      siteMetadata {
        title
      }
    }
    eno: wordpressPage(wordpress_id: { eq: 300 }) {
      title
      acf {
        eno_intro_title
        eno_intro_text
        eno_stap1_title
        eno_stap1_text
        eno_stap2_title
        eno_stap2_text
        eno_stap3_title
        eno_stap_3_text
        eno_stap4_title
        eno_stap4_text
        eno_outro_tekst
        eno_outro_btn1_text
        eno_outro_btn2_text
        meta_description
        meta_tags
      }
    }
  }
`
